<template>
  <div class="p-4" style="width: 100%;">
    <figure>
      <img :src="block.image.url" />
    <figcaption v-if="block.caption">{{block.caption}}</figcaption>
  </figure>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    
  },
  methods: {},
  mounted() {
    //
  },
};
</script>

<style lang="scss" scoped>
figure {
  text-align: center;
}
</style>